import './InvitationDetails.css';

import moment from 'moment';
import image from '../src/resources/iglesia-2.jpg'
// import mainImg from '../src/resources/main-img.svg';
import mainImg from '../src/resources/main-eiffel.png';
import casaBurgos from '../src/resources/casa-burgos-entrada.jpeg';
import esFlag from '../src/resources/flag/spain.png';
import brFlag from '../src/resources/flag/brazil.png';
import usFlag from '../src/resources/flag/united-states.png';
import {useState, useRef, useEffect} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import fontawesome from '@fortawesome/fontawesome'
import Button from 'react-bootstrap/Button';
import { faCheckSquare, faCoffee, faGlassMartini } from '@fortawesome/fontawesome-free-solid';
import textsEs from '../src/resources/i18n/es.json';
import textsPt from '../src/resources/i18n/pt.json';
import { useLocation } from "react-router-dom";
import { AnimationOnScroll } from 'react-animation-on-scroll';
import 'animate.css/animate.min.css';

fontawesome.library.add(faCheckSquare, faCoffee, faGlassMartini);

function InvitationDetails() {

    const weddingDate = moment(new Date('2024/8/3 18:00:00'));

    const [time, setTime] = useState(Date.now());

    
    useEffect(() => {
      const interval = setInterval(() => setTime(Date.now()), 1000);
      return () => {
        clearInterval(interval);
      };
    }, []);

    let currentDate = moment(new Date());
    let count = moment.duration(weddingDate.diff(currentDate));
    let days = Math.floor(count.asDays())
    let hours = count.hours();
    let mins = count.minutes();
    let seconds = count.seconds();
    //let texts = textsPt; // Es;
    const [texts, setLang] = useState(textsEs);
    const location = useLocation();
    const path = process.env['REACT_APP_' + location.pathname.slice(1)];
    const url = "https://tally.so/r/" + path;
    return (
      <div className="container">
        <div className="InvitationDetails">
          <div className="Invitation-details">
            <img src={mainImg} className='main-photo'></img>
            <div className="flags">
              <img className="flag" src={esFlag} onClick={() => setLang(textsEs)}></img>
              <img src={brFlag} className='flag' onClick={() => setLang(textsPt)}></img>
            </div>
            <div className="section-box">
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <div className='section-box-text'>
                  <p className="section-title">{texts["we-got-married"].toUpperCase()}</p>
                  <p className="section-title modal-title">Larissa</p>
                  <p className="section-title modal-title">{texts["and"]}</p>
                  <p className="section-title modal-title">Adriano</p>
                  <p className="section-title modal-manstein">{texts["august-3th-2024"]}</p>
                </div>
              </AnimationOnScroll>
            </div>
            <div className="blank-box"></div>
            <div className="bible">
              <AnimationOnScroll animateIn="animate__fadeInLeft">
               <p>{texts["colosenses"]}</p>
               <p className="quoteAuthor">{firstLetterUpper(texts["colosenses-sub"])}</p>
              </AnimationOnScroll>
            </div>
            <div className="blank-box"></div>
            <div className="section-box">
              <AnimationOnScroll animateIn="animate__fadeInLeft">
                <p className="section-content modal-title">
                  {firstLetterUpper(texts["with-god"])}
                </p>
                <p className="section-content modal-title">
                  {firstLetterUpper(texts["we-pleased"])}
                </p>
              </AnimationOnScroll>
            </div>
            <div className="blank-box"></div>
            <div className="section-box">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p className="section-content  modal-title">
              {firstLetterUpper(texts["remaining"])}</p>
              <div className="section-content">
                <div className="wrapper">
                  <div className="day-digit">{days}</div>
                  <div className="day-name">{texts["days"]}</div>
                  <div className="hour-digit">{hours}</div>
                  <div className="hour-name">{texts["hours"]}</div>
                  <div className="min-digit">{mins}</div>
                  <div className="min-name">{texts["minutes"]}</div>
                  <div className="second-digit">{seconds}</div>
                  <div className="second-name">{texts["seconds"]}</div>
                </div>
              </div>
              <p className="section-content  modal-title">
                {texts["until-our-wedding"]}
              </p>
              </AnimationOnScroll>
            </div>
            <div className="blank-box"></div>
            <div className="section-box">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p><img src={image} className='photo'></img></p>
              <div className='section-box-text'>
                <h2 className="section-title">{firstLetterUpper(texts["where-and-when"])}</h2>
                <h2 className="section-title">{firstLetterUpper(texts["church-wedding"])}</h2>
                <p>{texts['cristina-church']}</p>
                <p>17:30</p>
                <p>P.º de Extremadura, 32, Latina, 28011 Madrid</p>
                <a href="https://www.google.com/maps/place//data=!4m2!3m1!1s0xd42280a24334a39:0xb7c893884bc48ce4?sa=X&ved=1t:8290&ictx=111">
                  <Button variant="secondary">{firstLetterUpper(texts["watch-map"])}</Button>
                </a>
              </div>
              </AnimationOnScroll>
            </div>
            
            <div className="blank-box"></div>
            <div className="section-box">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <p><img src={casaBurgos} className='photo'></img></p>
              <div className='section-box-text'>
                <h2 className="section-title">{firstLetterUpper(texts["reception"])}</h2>
                <div className="section-content">
                  <p>Casa de Burgos</p>
                  <p>19:00</p> 
                  <p>C. Principal de Provincias, 3, Moncloa - Aravaca, 28011 Madrid</p>     
                  <a href="https://maps.app.goo.gl/5TNpFiVSPd4hsSqr7">
                    <Button variant="secondary">{firstLetterUpper(texts["watch-map"])}</Button>
                  </a>   
                </div>
              </div>
              </AnimationOnScroll>
            </div>
            <div className="blank-box"></div>
            <div className="section-box">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <h2 className="section-title">
                {firstLetterUpper(texts["celebration-details"])}
              </h2>
              <p>{firstLetterUpper(texts["celebration-details-description"])}</p>
              <div className="section-content">
                <div className="icon-section">
                  <p className='icon-text'>17:30</p>
                  <p className='modal-title'>
                  {firstLetterUpper(texts["church-wedding"])}
                  </p>
                  <FontAwesomeIcon icon="fa-solid fa-church" size="6x"/>
                </div>
                <div className="icon-section">
                  <p className='icon-text'>19:00</p>
                  <p className='modal-title'>Cocktail</p>
                  <FontAwesomeIcon icon="fa-solid fa-wine-glass" size="6x"/>
                </div>
                
                <div className="icon-section">
                  <p className='icon-text'>21:00</p>
                  <p className='modal-title'>{firstLetterUpper(texts["dinner"])}</p>
                  <FontAwesomeIcon icon="fa-solid fa-utensils" size="6x" />
                </div>
                
                <div className="icon-section">
                  <p className='icon-text'>23:30</p>
                  <p className='modal-title'>{firstLetterUpper(texts["party"])}</p>
                  <FontAwesomeIcon icon="fa-solid fa-music" size="6x"/>
                </div>
                <div className="icon-section">
                  <p className='icon-text'>5:30</p>
                  <p className='modal-title'>{firstLetterUpper(texts["end-party"])}</p>
                </div>
                <p>{texts['be-punctual']}</p>
              </div>
              </AnimationOnScroll>
            </div>
            <div className="section-box">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <h2 className="section-title">
              {firstLetterUpper(texts["dress-code"])}</h2>
              <FontAwesomeIcon icon="fa-solid fa-user-tie" size="6x"/>
              <p>{firstLetterUpper(texts["formal"])}</p>
              <p>{texts['please-not']}: </p>
              <div className='list'>
                <li>{texts['white-color']}</li>
                <li><a href="https://www.pinterest.es/pin/840765824206525758/">{texts['cream']}</a></li>
                <li>{texts['red-color']}</li>
                <li>{texts['chaque']}</li>
                <p>{texts['the-site']}</p>
              </div>
              </AnimationOnScroll>
            </div>
            <div className="blank-box"></div>
            <div className="section-box">
            <AnimationOnScroll animateIn="animate__fadeInLeft">
              <h2 className="section-title">
                {firstLetterUpper(texts['present'])}</h2>
              <p>{texts['our-best-present']}:</p>
              <div className='list'>
                <li>{texts['bank-transfer']}: 
                  <p>IBAN: ES60 0081 5243 4500 0196 2698</p>
                  <p>Larissa Chirolli Trajano Silva</p>
                </li>
                <li>Bizum:
                  <p>+34 682 87 38 93</p>
                </li>
              </div>
              </AnimationOnScroll>
            </div>
            <div className="blank-box"></div>
            {
            (path) &&
            
            <div className="section-box">
               <AnimationOnScroll animateIn="animate__fadeInLeft">
              <h2 className="section-title">{texts['i-go']}</h2>
              <p>{firstLetterUpper(texts["for-accept-invitation"])}</p>
              <a href={url}>
                <Button variant="secondary">{firstLetterUpper(texts["open-form"])}</Button>
              </a>
              
              <div className="blank-box"></div>
              
              </AnimationOnScroll>
              <p></p>
            </div>
            }
            <div className="blank-box"></div>
            <footer className='custom-footer'>
             <p>Author: Adriano Osses</p>
            </footer>
          </div>
        </div>
      </div>
    );
  }

  function firstLetterUpper(str) {
    return str.charAt(0).toUpperCase() +str.slice(1)
  }
  
  export default InvitationDetails;
  