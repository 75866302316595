import './App.css';
import InvitationDetails from './InvitationDetails';
import { BrowserRouter, Routes, Route } from "react-router-dom";
function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<InvitationDetails />}>
          <Route path=":id" element={<InvitationDetails />} />
          </Route>
        </Routes>
    </BrowserRouter>
  );
}

export default App;
